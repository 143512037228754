'use client';
import React, {useEffect} from "react";
import ReactDOM from "react-dom";
import {InstagramEmbed} from "react-social-media-embed";
import Heading from "@/components/Heading/Heading";


const RentalifyInstagramFeed: React.FC<{
  mobile?: boolean
}> = (props: {
  mobile?: boolean
}) => {
  const mobile = props.mobile;
  useEffect(() => {
    const instagram_embed = document.querySelectorAll(".instagram-embed");
    console.log('instagram_embed => ', instagram_embed);
    if (
      typeof instagram_embed !== "undefined" &&
      instagram_embed !== null &&
      Object.values(instagram_embed).length > 0
    ) {
      Object.values(instagram_embed).map((emb) => {
        console.log(emb.textContent);
        const url = emb?.textContent;
        // if (url?.startsWith("https")) {
        const newComponent = (
          <InstagramEmbed
            width={'100%'}
            height={mobile ? 300 : 500}
            url="https://www.instagram.com/rentalify.pl/"
          />
        );
        // @ts-ignore
        const root = ReactDOM.createRoot(emb);
        root.render(newComponent);
        return () => {
          root.unmount();
        };
        // }
      });
    }
  }, []);
  return (
    <>
      <Heading
        isCenter={true}
        asH2={true}
      >
        Bądź na bieżąco - instagram Rentalify.pl!
      </Heading>
      <div className={'flex w-full justify-center items-center'}>
        <div className={`instagram-embed flex ${mobile ? 'w-4/5' : 'w-10/12'}`}/>
      </div>
    </>

  )
}

export default RentalifyInstagramFeed;