import {sendGAEvent, sendGTMEvent} from '@next/third-parties/google'
import envs from "@/app/envs";

export const sendGaGtmEvent = (eventName: string, eventValue: any) => {
  if (envs.APP_ENV === 'production') {
    console.log(`===== eventValue: ${eventValue} =====`);
    if (eventValue === 'WARSZAWA') {
      sendGAEvent({event: "HOME_LOCATIONS_CLICK", value: 'WARSZAWA'})
      sendGAEvent('event', 'HOME_LOCATIONS_CLICK', {value: 'WARSZAWA'})
      sendGTMEvent({
        event: 'HOME_LOCATIONS_CLICK',
        value: 'WARSZAWA'
      })
    }
    sendGAEvent('event', 'HOME_TEST_A', {value: eventValue})
    sendGAEvent('event', 'HOME_TEST_B', {
      placement: 'homepage',
      instance: 'city',
      value: eventValue
    })
    sendGTMEvent({
      event: 'HOME_TEST_GTM_A',
      value: eventValue
    })
    sendGTMEvent({
      event: 'HOME_TEST_GTM_B',
      value: {
        placement: 'homepage',
        instance: 'city',
        value: eventValue
      }
    })
    sendGTMEvent({
      event: 'HOME_TEST_GTM_C',
      value: eventValue, placement: 'homepage', instance: 'city'
    })
  }

}