'use client'
import React, {useState} from "react";
import RentalioBtnSearchGearAlgolia from "./RentalioBtnSearchGearAlgolia";
import {useRouter} from "next/navigation";
import {niceUrlEncoder} from "@/components/RentalifyGearSearchFormAlgolia/newSearchSideBar/utils/niceUrlEncoder";
import GearSearchInput from "@/components/RentalifyGearSearchFormAlgolia/GearSearchInput";


const RentalifyHomepageGearSearchInput = ({mobile}: { mobile: boolean }) => {

  const router = useRouter();
  const [searchQuery, setSearchQuery] = useState<string>('');

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      const url = `/lista-sprzetu/${niceUrlEncoder(searchQuery || '')}`;
      router.push(url);
    }
  }
  /*
            bg-gradient-to-br from-green-800/70 to-green-400
            w-full relative mt-8 shadow-2xl shadow-green-800/30
            flex-coll rounded-xl
            p-4 space-y-2
   */
  return (
    <div className={`flex flex-row items-center justify-center`}>
      <div
        className="relative w-full max-w-screen-md flex flex-row items-center justify-center"
      >
        <GearSearchInput
          type={'text'}
          onKeyDown={handleKeyDown}
          className={`
              rounded-2xl
              lg:rounded-3xl
              w-full h-16 
              bg-white
              text-base lg:text-base text-black/80 font-bold
              hover:shadow-lg hover:shadow-green-800/50
              border-green-800 focus:border-green-600 
              focus:ring focus:ring-green-200 focus:ring-opacity-50
            `}
          value={searchQuery}
          placeholder={mobile ? `Lumix S5IIX / gimbal warszawa...` : `np. Lumix S5IIX / gimbal warszawa / Sony wrocław...`}
          onChange={(e) => e.target.value && e.target.value !== ''
            ? setSearchQuery(e.target.value)
            : setSearchQuery('/')
          }
        />
        <RentalioBtnSearchGearAlgolia
          searchQuery={searchQuery}
          className={`
              absolute right-2
              flex lgm:w-1/8 w-12 h-12  lgm:ml-1 lg:ml-2
              rounded-2xl border-2 border-green-800 hover:border-green-600
              items-center justify-center text-neutral-50 focus:outline-none
            `}
        />
      </div>
    </div>
  );
};

export default RentalifyHomepageGearSearchInput;
