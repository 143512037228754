'use client'
import React, {FC} from "react";
import {TaxonomyType} from "@/data/types";
import NcImage from "@/components/shared/NcImage/NcImage";
import Link from "next/link";
import {useClientMediaQuery} from '@/hooks/useClientMediaQuery';
import {sendGaGtmEvent} from "@/lib/utils/sendGAEvent";

export interface RentalifyGridComponentProps {
  className?: string;
  taxonomy: TaxonomyType;
  mobile?: boolean;
}

const RentalifyGridComponentVertical: FC<RentalifyGridComponentProps> = (
  props
) => {
  const isMobile = useClientMediaQuery('(max-width: 959px)');
  const {
    className = "",
    taxonomy,
    mobile = false,
  } = props;

  const handleSendEvents = (taxonomyValue: string) => {
    sendGaGtmEvent('', taxonomyValue);
  }
  const {name, thumbnail, href = "/"} = taxonomy;
  return (
    <Link
      onClick={() => handleSendEvents(taxonomy.value)}
      href={href}
      className={`
        relative flex flex-col items-center
        space-y-2
        ${className}
      `}
    >
      <div className={`
        rounded-xl overflow-hidden 
        h-28 w-28
        lg:h-72 lg:w-full
      `}>
        <NcImage
          src={thumbnail}
          className={`h-full`}
          containerClassName="h-full w-full"
          width={isMobile ? 150 : 300}
          height={isMobile ? 150 : 600}/>
      </div>
      <h2 className="text-base font-medium">
        <span>{name}</span>
      </h2>
      <div
        className={`block lgm:mt-1 lg:mt-2 text-sm text-neutral-500`}
      >
        Sprzętów: {taxonomy.count}
      </div>
    </Link>
  );
};

export default RentalifyGridComponentVertical;
