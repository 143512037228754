'use client'
import React, {FC, useEffect, useState} from "react";
import ButtonCircle from "@/components/shared/Button/ButtonCircle";
import NcImage from "@/components/shared/NcImage/NcImage";
import Input from "@/components/shared/Input/Input";
import {API} from "@/lib/API";
import Checkbox from "@/components/shared/Checkbox/Checkbox";

export interface SectionSubscribe2Props {
  className?: string;
}

const RentalioNewsLetter: FC<SectionSubscribe2Props> = ({className = ""}) => {
  const [saveToNewsletter, setSaveToNewsletter] = useState<boolean>(false);
  const [newsletterEmailValue, setNewsletterEmailValue] = useState<string>('');
  const [newsletterCheckbox, setNewsletterCheckbox] = useState<boolean>(false);
  const [newsletterCheckboxError, setNewsletterCheckboxError] = useState<boolean>(false);
  const [saved, setSaved] = useState<boolean>(false);
  const handleNewsletterChange = (event: any) => {
    const {field, target} = event;
    const {value} = target;
    const pattern = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    const emailMatch = value && value.match(pattern);
    if (emailMatch) {
      setNewsletterEmailValue(value)
    } else {
      setNewsletterEmailValue('')
    }
  }
  const handleSaveToNewsLetter = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    if(newsletterCheckbox){
      setSaveToNewsletter(true);
    } else {
      setNewsletterCheckboxError(true);
    }
  }

  useEffect(() => {
    if (saveToNewsletter && newsletterEmailValue) {
      API.NEWSLETTER_NEW_USER({
        email: newsletterEmailValue,
      })
        .then(() => {
          setSaveToNewsletter(false);
          setSaved(true);
        });
    }
  }, [saveToNewsletter]);
  return (
    <div
      className={`nc-SectionSubscribe2 relative flex flex-col lg:flex-row lg:items-center ${className}`}
      data-nc-id="SectionSubscribe2"
    >
      <div className="flex-shrink-0 mb-10 lg:mb-0 lg:mr-10 lg:w-2/5">
        <h2 className="font-semibold text-3xl">Dołącz do newslettera 🎉</h2>
        <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
          Dowiedz się pierwszy o uruchomieniu systemu oraz najnowszych zmianach!
        </span>
        {/*<ul className="space-y-4 mt-10">*/}
        {/*  <li className="flex items-center space-x-4">*/}
        {/*    <Badge name="01" />*/}
        {/*    <span className="font-medium text-neutral-700 dark:text-neutral-300">*/}
        {/*      Get more discount*/}
        {/*    </span>*/}
        {/*  </li>*/}
        {/*  <li className="flex items-center space-x-4">*/}
        {/*    <Badge color="red" name="02" />*/}
        {/*    <span className="font-medium text-neutral-700 dark:text-neutral-300">*/}
        {/*      Get premium magazines*/}
        {/*    </span>*/}
        {/*  </li>*/}
        {/*</ul>*/}
        {
          !saved
            ? <form className="mt-10 relative max-w-sm">
              <Input
                aria-required
                placeholder="Twój email"
                type="email"
                rounded="rounded-full"
                onChange={(e) => handleNewsletterChange({...e, field: 'email'})}
              />
              {
                newsletterEmailValue &&
                <ButtonCircle
                  className="absolute transform top-1/2 -translate-y-1/2 right-[5px]"
                  onClick={(e) => handleSaveToNewsLetter(e)}
                >
                  <i className="las la-arrow-right text-xl"></i>
                </ButtonCircle>
              }
            </form>
            : <span className="block mt-5 font-medium text-neutral-500 dark:text-neutral-400">
                Zapisano do newslettera!
              </span>
        }
        {
          newsletterEmailValue && !saved &&
          <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
            <Checkbox
              className={`${newsletterCheckboxError ? 'border-2 border-red-500' : ''}`}
              name="newsLetterCheckbox"
              label="Zapoznałem się z polityką prywatności i regulaminem portalu Rentalify.pl."
              defaultChecked={newsletterCheckbox}
              onChange={(e) => {
                if(!newsletterCheckbox) {
                  setNewsletterCheckboxError(false)
                }
                setNewsletterCheckbox(!newsletterCheckbox)
              }}
            />
          </span>
        }
      </div>
      <div className="flex-grow">
        <NcImage src={'/public/common/SVG-subscribe2.png'}/>
      </div>
    </div>
  );
};

export default RentalioNewsLetter;
