import React, {FC} from "react";
import Link from "next/link";
import {niceUrlEncoder} from "@/components/RentalifyGearSearchFormAlgolia/newSearchSideBar/utils/niceUrlEncoder";

interface Props {
  searchQuery: string;
  className?: string;
}

const RentalioBtnSearchGearAlgolia: FC<Props> = ({searchQuery, className}) => {
  return (
    <Link
      href={`/lista-sprzetu/${searchQuery ? niceUrlEncoder(searchQuery) : '/'}`}
      type="button"
      className={`${className}`}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="#2e7d32"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={1.5}
          d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
        />
      </svg>
    </Link>
  );
};

export default RentalioBtnSearchGearAlgolia;
