'use client'
import React, {FC} from "react";
import {TaxonomyType} from "@/data/types";
import NcImage from "@/components/shared/NcImage/NcImage";
import Link from "next/link";
import {useClientMediaQuery} from '@/hooks/useClientMediaQuery';
import {sendGaGtmEvent} from "@/lib/utils/sendGAEvent";

export interface RentalifyGridComponentProps {
  className?: string;
  taxonomy: TaxonomyType;
}

const RentalifyGridComponent: FC<RentalifyGridComponentProps> = (
  props
) => {
  const isMobile = useClientMediaQuery('(max-width: 959px)');
  const {
    className = "",
    taxonomy,
  } = props;

  const handleSendEvents = (taxonomyValue: string) => {
    sendGaGtmEvent('', taxonomyValue);
  }
  const {count, name, thumbnail, href = "/"} = taxonomy;
  return (
    <Link
      onClick={() => handleSendEvents(taxonomy.value)}
      href={href}
      className={`
        nc-CardCategoryBox1 relative flex items-center lg:p-3 lgm:p-1
        [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ]  ${className}
      `}
      data-nc-id="CardCategoryBox1"
    >
      {/*<Badge*/}
      {/*  className="absolute right-2 top-2"*/}
      {/*  color="gray"*/}
      {/*  name={convertNumbThousand(count)}*/}
      {/*/>*/}

      <div className="relative flex-shrink-0 lg:w-24 lg:h-24 lgm:h-10 lgm:w-10 lgm:ml-1 rounded-full overflow-hidden">
        <NcImage src={thumbnail} containerClassName="absolute inset-0" width={isMobile ? 150 : 300}
                 height={isMobile ? 150 : 300}/>
      </div>
      <div className="lgm:ml-2 ml-4 flex-grow overflow-hidden">
        <h2 className="text-base font-medium">
          <span>{name}</span>
        </h2>
        <span
          className={`block lgm:mt-1 lg:mt-2 text-sm text-neutral-500`}
        >
          Sprzętów: {taxonomy.count}
        </span>
      </div>
    </Link>
  );
};

export default RentalifyGridComponent;
