import React, { InputHTMLAttributes } from "react";

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  className?: string;
}

const GearSearchInput = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      ...args
    },
    ref
  ) => {
    return (
      <input
        ref={ref}
        type={'text'}
        className={`${className}`}
        {...args}
      />
    );
  }
);
GearSearchInput.displayName = 'Input';

export default GearSearchInput;
